.docExplorerWrap {
  height: unset !important;
  min-width: unset !important;
  width: unset !important;
}

.doc-explorer-title {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-medium);
}

.doc-explorer-rhs {
  display: none;
}

.graphiql-explorer-root {
  font-family: var(--font-family-mono) !important;
  font-size: var(--font-size-body) !important;
  padding: 0 !important;
}

.graphiql-explorer-root > div:first-child {
  padding-left: var(--px-8);
}

.graphiql-explorer-root input {
  background: hsl(var(--color-base));
}

.graphiql-explorer-root select {
  background-color: hsl(var(--color-base));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-secondary));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  margin: 0 var(--px-4);
  padding: var(--px-4) var(--px-6);
}
