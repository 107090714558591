@tailwind components;

@layer components {
  .rapid-select-container .rapid-select__control {
    @apply cursor-pointer border border-gray-200 bg-popover dark:border-zinc-700;
  }

  .rapid-select-container .rapid-select__menu {
    @apply border bg-popover px-1 shadow;
  }

  .rapid-select-container .rapid-select__option {
    @apply truncate rounded-sm bg-popover py-1.5  text-sm  text-popover-foreground hover:bg-accent hover:text-accent-foreground;
  }

  .rapid-select__value-container {
    @apply px-3 py-2;
  }

  .rapid-select-container .rapid-select__indicator-separator {
    @apply bg-transparent;
  }

  .rapid-select__control .rapid-select__control--menu-is-open {
    @apply border;
  }

  .rapid-select__control .rapid-select__control--is-focused {
    @apply shadow-none outline-none;
  }

  .rapid-select__menu-list {
    @apply p-0;
  }

  .rapid-select-container .rapid-select__input-container,
  .rapid-select-container .rapid-select__placeholder,
  .rapid-select-container .rapid-select__single-value {
    @apply text-sm text-foreground;
  }

  .rapid-select-container .rapid-select__placeholder {
    @apply text-secondary;
  }

  .rapid-select-container .rapid-select__dropdown-indicator {
    @apply opacity-50 [&>svg]:h-4 [&>svg]:w-4;
  }

  .rapid-select-container .rapid-select__value-container .rapid-select__value-container--is-multi {
    @apply px-3;
  }

  .rapid-select__option.rapid-select__option--is-focused {
    @apply bg-accent;
  }
}
