.endpoint-group {
  display: grid;
  justify-items: start;
  justify-content: flex-start;
  @apply gap-y-3;
}
.data-row {
  @apply col-start-2 col-end-2 flex justify-self-stretch overflow-hidden text-xs font-normal text-slate-500;
}
.data-row::before {
  content: '';
  position: absolute;
  left: 6px;
  margin-top: -3px;
  width: 230px;
  height: 24px;
  z-index: 1;
  @apply rounded;
}
